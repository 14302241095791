const HomePage = (props: { userName: string }) => {
    const userName = props.userName;
    return (
        <div style={{margin: '20%', textAlign: 'center'}}>
            <h1>Hello, {userName}!</h1>
            <h3>Welcome to Kerridge Portal</h3>
        </div>
    )
}

export default HomePage;