export const msalConfig = {
    auth: {
        clientId: (process.env.REACT_APP_MSAL_CLIENT_ID as string),
        authority: (`https://login.microsoftonline.com/${process.env.REACT_APP_MSAL_TENANT_ID}` as string), // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: (process.env.REACT_APP_MSAL_REDIRECT_URL_DEV as string),
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const graphLoginRequest = {
    scopes: ["User.Read"]
};

export const apiLoginRequest = {
    scopes: ["api://47bdcf01-321c-4f9d-982c-0cc83d3effe1/access_as_user"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: (process.env.REACT_APP_MSAL_GRAPH_ENDPOINT as string)
};