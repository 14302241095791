import { registerMicroApps, start } from 'qiankun';
import mainAppProps from './MainAppProps';

registerMicroApps(
    [
        {
            name: 'proposal-generator',
            entry: 'https://localhost:3001',
            container: '#proposal-generator-container',
            activeRule: '/proposalgenerator',
            props: { mainAppProps }
        },
        {
            name: 'vtt',
            entry: 'https://localhost:3002',
            container: '#vtt-container',
            activeRule: '/vtt',
            props: { mainAppProps }
        }
    ],
    {
        beforeLoad: () => {
            return new Promise<void>((resolve) => {
                resolve();
            });
        },
        beforeMount: () => {
            return new Promise<void>((resolve) => {
                resolve();
            });
        },
        afterMount: () => {
            return new Promise<void>((resolve) => {
                resolve();
            });
        },
        beforeUnmount: () => {
            return new Promise<void>((resolve) => {
                resolve();
            });
        },
        afterUnmount: () => {
            return new Promise<void>((resolve) => {
                resolve();
            });
        },
    })

start();