import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import BaseContentContainer from './components/BaseContainer';
import HomePage from './pages/HomePage'
import ProposalGenerator from './pages/ProposalGenerator';
import VTT from './pages/VTT';
import { useMsal, useIsAuthenticated, useAccount } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { useEffect, useState } from "react";
import { apiLoginRequest, graphConfig, msalConfig } from "./authConfig";
import mainAppProps from './MainAppProps';
import { createClient, Session } from "@supabase/supabase-js";

export interface UserInfo {
  '@odata.context': string;
  businessPhones: number[];
  displayName: string;
  givenName: string;
  id: string;
  jobTitle?: string;
  mail: string;
  mobilePhone?: string;
  officeLocation?: string;
  preferredLanguage?: string;
  surName: string;
  userPrincipalName: string;
}

const supabase = createClient("https://mdllnhgquuqrdjpxmcbu.supabase.co", process.env.REACT_APP_SUPABASE_ANON_KEY as string);

function App() {

  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const account = useAccount(accounts[0] || {});
  const [apiData, setApiData] = useState<UserInfo | null>(null);
  const name: string = apiData ? apiData['givenName'] : '';

  async function callMsGraph(accessToken: string) {
    if (!accessToken) {
      const account = instance.getActiveAccount();
      if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
      }

      const response = await instance.acquireTokenSilent({
        ...apiLoginRequest,
        account: account
      });
      accessToken = response.accessToken;
    }

    const headers = new Headers();
    console.log("token app.tsx: ", accessToken);
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("X-Api-Key-tqhV94RQ5C6fj2", "D6CBB360739344AF93DF487DF786DCF0");

    const options: RequestInit = {
      method: "GET",
      headers: headers,
      mode: "no-cors"
    };

    console.log("graphConfig.graphMeEndpoint: ", graphConfig.graphMeEndpoint);
    return fetch(graphConfig.graphMeEndpoint, options)
      .then(response => response.json())
      .catch(error => console.log(error));
  }

  // useEffect(() => {
  //   if (account) {
  //     console.log('instance: ', instance);
  //     instance.acquireTokenSilent({
  //       ...apiLoginRequest,
  //       account: account
  //     }).then((response) => {
  //       if (response) {
  //         console.log('response.accessToken: ', response.accessToken)
  //         callMsGraph(response.accessToken).then((result) => { setApiData(result) });
  //       }
  //     }).catch((e) => {
  //       console.log(e);
  //     });
  //   }
  // }, [account, instance]);

  // useEffect(() => {
  //   if (apiData?.mail) {
  //     mainAppProps.setUserEmail(apiData?.mail ? apiData?.mail : '');
  //     console.log('main app getUserEmail: ', mainAppProps.getUserEmail());
  //   }
  // }, [apiData])

  // useEffect(() => {
  //   const login = async () => {
  //     if (!isAuthenticated && inProgress === InteractionStatus.None) {
  //       instance.loginRedirect(apiLoginRequest).catch((e) => {
  //         console.log(e);
  //       });
  //     }
  //   }
  //   login();
  // }, [isAuthenticated, inProgress, instance]);

  const [session, setSession] = useState<Session | null>(null);

  // useEffect(() => {
  //   supabase.auth.getSession().then(({ data: { session } }) => {
  //     setSession(session)
  //   })

  //   signInWithAzure();
  //   async function signInWithAzure() {
  //     const { data, error } = await supabase.auth.signInWithOAuth({
  //       provider: 'azure',
  //       options: {
  //         scopes: 'email',
  //       },
  //     });
  //     if (error) {
  //       console.log(error);
  //     }
  //     alert(JSON.stringify(data));
  //     console.log(data);
  //   };
  // }, [])


  return (
    <div className="App">
      {/* {
        isAuthenticated ?
          <Router>
            <Routes>
              <Route path='/' element={<BaseContentContainer userName={name} />}>
                <Route index element={<HomePage userName={name} />} />
                <Route path='proposalgenerator' element={<ProposalGenerator />} />
                <Route path='vtt' element={<VTT />} />
              </Route>
            </Routes>
          </Router>
          :
          <p>Please log in first</p>
      } */}
      <Router>
        <Routes>
          <Route path='/' element={<BaseContentContainer userName={name} />}>
            <Route index element={<HomePage userName={name} />} />
            <Route path='proposalgenerator' element={<ProposalGenerator />} />
            <Route path='vtt' element={<VTT />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
