import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import ArticleIcon from '@mui/icons-material/Article';
import MicIcon from '@mui/icons-material/Mic';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import SsidChartIcon from '@mui/icons-material/SsidChart';

export function SidebarItems() {
    const navigate = useNavigate();
    const SidebarItems = (
        <Fragment>
            <ListItemButton onClick={() => navigate("/")}>
                <ListItemIcon>
                    <HomeIcon />
                </ListItemIcon>
                <ListItemText primary='Home' />
            </ListItemButton>
            <ListItemButton onClick={() => navigate("/proposalgenerator")}>
                <ListItemIcon>
                    <ArticleIcon />
                </ListItemIcon>
                <ListItemText primary='Proposal Generator' />
            </ListItemButton>
            <ListItemButton onClick={() => navigate("/vtt")}>
                <ListItemIcon>
                    <MicIcon />
                </ListItemIcon>
                <ListItemText primary='Voice-To-Text' />
            </ListItemButton>
            <ListItemButton>
                <ListItemIcon>
                    <ArtTrackIcon />
                </ListItemIcon>
                <ListItemText primary='Form Recognizer' />
            </ListItemButton>
            <ListItemButton>
                <ListItemIcon>
                    <SsidChartIcon />
                </ListItemIcon>
                <ListItemText primary='Performance Measure' />
            </ListItemButton>
        </Fragment>
    )
    return SidebarItems;
}