import { useMsal } from "@azure/msal-react";
import { PortalButton } from "./StyledComponents";

const LogoutButton = () => {

    const { instance } = useMsal();

    const handleLogout = (logoutType: string) => {
        if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    }

    return (
        <PortalButton variant="contained" onClick={() => handleLogout("redirect")}>LOG OUT</PortalButton>
    )
}

export default LogoutButton;