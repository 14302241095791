import { useMsal } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
import { apiLoginRequest } from "../authConfig";
import { PortalButton } from "./StyledComponents";

const LoginButton = () => {

    const { instance } = useMsal();

    const handleLogin = async (loginType: string) => {
        if (loginType === "redirect") {
            const accounts = instance.getAllAccounts();
            if (accounts.length > 0) {
                instance.setActiveAccount(accounts[0]);
            }

            instance.addEventCallback((event: { eventType: EventType; payload: { account: any; }; }) => {
                // set active account after redirect
                if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
                    const account = event.payload.account;
                    instance.setActiveAccount(account);
                }
            });

            // handle auth redired/do all initial setup for msal
            instance.handleRedirectPromise().then(async authResult => {
                // Check if user signed in 
                const account = instance.getActiveAccount();
                if (!account) {
                    // redirect anonymous user to login page 
                    await instance.loginRedirect(apiLoginRequest);
                }
            }).catch(err => {
                // TODO: Handle errors
                console.log(err);
            });
        }
    }

    return (
        <PortalButton variant="contained" onClick={async () => await handleLogin("redirect")}>LOGIN</PortalButton>
    )
}

export default LoginButton;