import { Outlet } from 'react-router-dom';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useState } from 'react';
import { SidebarItems } from './SidebarItems';
import PortalVersionString from "./PortalVersionString";
import { useIsAuthenticated } from "@azure/msal-react";
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';

const drawerWidth: number = 260;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
            backgroundColor: '#90c444',
            overflowX: 'hidden',
        },
    }),
);

const KerridgeLogo = styled('img')({
    height: '55%',
    position: 'absolute',
    left: '4%',
    top: '20%'
});

const mdTheme = createTheme();

const BaseContentContainer = (props: { userName: string }) => {
    const userName = props.userName;

    const [open, setOpen] = useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    }

    const isAuthenticated = useIsAuthenticated();

    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position='absolute' open={open} sx={{ backgroundColor: '#FFFFFF' }}>
                    <Toolbar sx={{ pr: '24px' }}> {/* Keep right padding when drawer closed */}
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px',
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon color='action' />
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="black"
                            noWrap
                            sx={{ flexGrow: 1 }}
                            style={{ cursor: 'pointer' }}
                        // onClick={handleLogoClick}
                        >
                            <KerridgeLogo src={'/logo_kerriidge_500x500.png'} alt='logo' />
                        </Typography>
                        {isAuthenticated ? <><p style={{marginRight: '2%', color: 'black'}}>{userName}</p><LogoutButton /></> : <LoginButton />}
                    </Toolbar>
                </AppBar>
                <Drawer variant='permanent' open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                            // backgroundColor: '#FFFFFF',
                        }}
                    >
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <List component="nav">
                        <SidebarItems />
                    </List>
                    {open ?
                        <PortalVersionString /> : <></>
                    }
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth={false}>

                        <Outlet />

                    </Container>

                </Box>
            </Box>
        </ThemeProvider>
    )
}

export default BaseContentContainer;