import { Button } from "@mui/material";
import styled from "@emotion/styled";

const PortalButton = styled(Button)(() => ({
    backgroundColor: '#90c444',
    "&:hover": {
        backgroundColor: '#83b637'
    }
}));

export { PortalButton }